<template lang="">
    <div>
        <b-row style="background:#f4f4f4">
            <b-col>
                <div>
                    <h4 class="mt-4 ml-4">Passaic OU4 Sample Tracking</h4>
                </div>
            </b-col>
        </b-row>
        <div class="p-3 tableContainer">
            <div
                class="d-flex justify-content-center align-items-center "
                style="min-height: 80vh;"
            >
                <div class="text-center">
                    <b-row>
                        <b-col cols="6">
                            <div class="tableClass p-2">
                                <div class="tableInnerClass">
                                    <b-row>
                                        <b-col>
                                            <div
                                                style="text-align: left; "
                                                class="mb-1 mt-1"
                                            >
                                                <span>SDG Number</span>
                                            </div>
                                            <div class="mb-2 mr-3">
                                                <b-input-group
                                                    size="sm"
                                                    class=""
                                                >
                                                    <b-input-group-prepend
                                                        is-text
                                                    >
                                                        <b-icon
                                                            icon="search"
                                                        ></b-icon>
                                                    </b-input-group-prepend>
                                                    <b-form-input
                                                        class="custome-input custom-placeholder"
                                                        placeholder="Search for SDG Numbers"
                                                        v-model="
                                                            sdgNumberSearch
                                                        "
                                                    ></b-form-input>
                                                </b-input-group>
                                            </div>
                                            <b-table
                                                id="sdg-table"
                                                :items="searchSdgs"
                                                :fields="SDGfields"
                                                responsive="sm"
                                                ref="selectableTable"
                                                selectable
                                                :per-page="perPage"
                                                :current-page="sdgCurrentPage"
                                                selected-variant="white"
                                                :outlined="true"
                                                thead-class="hidden_header"
                                                tbody-class="table-body"
                                                tbody-tr-class="table-body-tr"
                                                @row-selected="updateCheckboxes"
                                            >
                                                <template
                                                    #cell(isChecked)="data"
                                                >
                                                    <b-form-checkbox
                                                        v-model="
                                                            data.item.isChecked
                                                        "
                                                        :value="true"
                                                        :unchecked-value="false"
                                                    >
                                                    </b-form-checkbox>
                                                </template>
                                            </b-table>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div class="paginationClass">
                                    <b-row>
                                        <b-col>
                                            <b-col>
                                                <div class="alignCenter">
                                                    <b-pagination
                                                        v-model="sdgCurrentPage"
                                                        :total-rows="sdgRows"
                                                        :per-page="perPage"
                                                        aria-controls="sdg-table"
                                                        @change="
                                                            paginationChanged()
                                                        "
                                                        class="custom-pagination"
                                                    ></b-pagination>
                                                </div>
                                            </b-col>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="6">
                            <div class="tableClass  p-2">
                                <div class="tableInnerClass">
                                    <b-row>
                                        <b-col>
                                            <div
                                                style="text-align: left; "
                                                class="mb-1 mt-1"
                                            >
                                                <span>Chemist</span>
                                            </div>
                                            <div class="mb-2 mr-3">
                                                <b-input-group
                                                    size="sm"
                                                    class=""
                                                >
                                                    <b-input-group-prepend
                                                        is-text
                                                    >
                                                        <b-icon
                                                            icon="search"
                                                        ></b-icon>
                                                    </b-input-group-prepend>
                                                    <b-form-input
                                                        class="custome-input custom-placeholder"
                                                        placeholder="Search for Chemist"
                                                        v-model="
                                                            chemNumberSearch
                                                        "
                                                    ></b-form-input>
                                                </b-input-group>
                                            </div>
                                            <b-table
                                                id="checmist-table"
                                                :items="searchChemists"
                                                :fields="ChemistsFields"
                                                responsive="sm"
                                                ref="selectableTable"
                                                selectable
                                                :per-page="perPage"
                                                :current-page="currentPage"
                                                selected-variant="white"
                                                :outlined="true"
                                                thead-class="hidden_header"
                                                tbody-class="table-body"
                                                tbody-tr-class="table-body-tr"
                                                @row-clicked="updateRadioboxes"
                                            >
                                                <template
                                                    #cell(isSelected)="data"
                                                >
                                                    <b-form-radio
                                                        v-model="
                                                            selectedChemist
                                                        "
                                                        :aria-describedby="
                                                            ariaDescribedby
                                                        "
                                                        name="some-radios"
                                                        :value="
                                                            data.item.FullName
                                                        "
                                                    ></b-form-radio>
                                                </template>
                                            </b-table>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div class="paginationClass">
                                    <b-row>
                                        <b-col>
                                            <div class="alignCenter">
                                                <b-pagination
                                                    v-model="currentPage"
                                                    :total-rows="rows"
                                                    :per-page="perPage"
                                                    aria-controls="checmist-table"
                                                    @change="
                                                        chemPaginationChanged()
                                                    "
                                                    class="custom-pagination"
                                                ></b-pagination>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>
        <div class="saveButtonContainer">
            <b-row
                class="justify-content-center align-items-center"
                style="height: 100%;"
            >
                <b-col cols="auto" class="text-center">
                    <b-button
                        style="background:#006395; width:120px; height:48px"
                        class="custom-button"
                        @click="submit"
                    >
                        Submit
                    </b-button>
                </b-col>
            </b-row>
        </div>
        <ToastMessages
            :showSuccess="showSuccess"
            :showFailure="showFailure"
            :failureToastMessage="failureToastMessage"
            :successToastMessage="successToastMessage"
        ></ToastMessages>
    </div>
</template>
<script>
import { commonService } from '../../services/common.service';
import ToastMessages from '../../components/ToastMessages.vue';
export default {
    data() {
        return {
            SDGfields: [
                {
                    key: 'isChecked',
                    label: '',
                },
                {
                    key: 'Lab_SDG_No',
                    label: 'Lab_SDG_No',
                },
            ],
            ChemistsFields: [
                {
                    key: 'isSelected',
                    label: '',
                },
                {
                    key: 'FullName',
                    label: 'Full Name',
                },
            ],
            SDGItems: [],
            Chemists: [],
            stgSelected: [],
            selectedChemists: [],
            perPage: 10,
            currentPage: 1,
            sdgCurrentPage: 1,
            sdgPageChanged: false,
            chemPageChanged: false,
            selectedChemist: null,
            sdgNumberSearch: '',
            chemNumberSearch: '',
            showSuccess: false,
            showFailure: false,
            failureToastMessage: null,
            successToastMessage: null,
        };
    },
    components: {
        ToastMessages,
    },
    methods: {
        GetSDGs() {
            commonService.getSDGs().then((res) => {
                this.SDGItems = res.data.map((item, index) => ({
                    ...item,
                    isChecked: index < 10,
                }));
            });
        },
        getChemists() {
            commonService.getChemists().then((res) => {
                this.Chemists = res.data;
            });
        },
        paginationChanged() {
            this.sdgPageChanged = true;
        },
        chemPaginationChanged() {
            this.chemPageChanged = true;
        },
        updateCheckboxes(selectedItems) {
            let tempItem = this.SDGItems.find(
                (item) => item.Lab_SDG_No === selectedItems[0].Lab_SDG_No
            );
            console.log(tempItem);
            if (tempItem) {
                this.SDGItems = this.SDGItems.map((i) =>
                    i.Lab_SDG_No === tempItem.Lab_SDG_No
                        ? { ...i, isChecked: !i.isChecked }
                        : i
                );
            }
        },
        updateRadioboxes(selectedItems) {
            this.selectedChemist = selectedItems.FullName;
        },
        submit() {
            let ProjectId = this.SDGItems[0].Project_Id;
            let sdgArray = this.SDGItems.filter((item) => item.isChecked).map(
                (item) => item.Lab_SDG_No
            );
            let toSent = {
                ProjectId: Number(ProjectId),
                ChemistId: Number(
                    this.Chemists.find(
                        (item) => item.FullName === this.selectedChemist
                    ).Contact_Id
                ),
                SDGs: sdgArray,
            };
            commonService
                .SubmitSDGs(toSent)
                .then((res) => {
                    if (res.status === 200 || res.status === 201) {
                        this.showSuccess = true;
                        this.successToastMessage = res.data.Message;
                        setTimeout(() => {
                            this.showSuccess = false;
                        }, 5000);
                    } else {
                        this.showFailure = true;
                        this.failureToastMessage = res.data;
                        setTimeout(() => {
                            this.showFailure = false;
                        }, 5000);
                    }
                    this.GetSDGs();
                })
                .error((res) => {
                    this.showFailure = true;
                    this.failureToastMessage =
                        "We've run into an error. Please contact your system administrator for further assistance.";
                    setTimeout(() => {
                        this.showFailure = false;
                    }, 5000);
                });
        },
    },
    mounted() {
        this.GetSDGs();
        this.getChemists();
    },
    computed: {
        rows() {
            return this.Chemists.length;
        },
        sdgRows() {
            return this.SDGItems.length;
        },
        searchSdgs() {
            return this.SDGItems.filter((item) => {
                return item.Lab_SDG_No.toLowerCase().includes(
                    this.sdgNumberSearch.toLowerCase()
                );
            });
        },
        searchChemists() {
            return this.Chemists.filter((item) => {
                return item.FullName.toLowerCase().includes(
                    this.chemNumberSearch.toLowerCase()
                );
            });
        },
    },
};
</script>
<style lang="scss">
.activeRow {
    background-color: transparent;
}
.alignCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}
.tableContainer {
    position: relative;
    height: 100%;
    width: 100%;
}
.tableClass {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: buttonface;
}

.tableInnerClass {
    flex: 1;
}

.paginationClass {
    margin-top: auto;
}
.saveButtonContainer {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100px;
    width: 100%;
    background: #f4f4f4;
}
.custom-pagination {
    .page-link {
        background-color: buttonface !important;
        border: none;
    }

    .page-item.active .page-link {
        background-color: gray !important;
        border-color: gray !important;
        color: white !important;
    }
}
.hidden_header {
    display: none;
}
.borderless-input {
    border: none !important;
    box-shadow: none !important;
    border-radius: 0;
}

.custome-input {
    font-weight: 100 !important;
    color: #777777;
}
.custom-placeholder::placeholder {
    font-weight: 100 !important;
    color: #777777;
}
.custome-input:focus {
    box-shadow: none;
    border: 1px solid #ced4da;
}
.table-body {
    height: 500px;
}
.table-body-tr {
    height: 50px;
    float: left;
    width: 100%;
}
.table-body-tr:nth-child(odd) {
    background-color: rgba(255, 255, 255, 0.555);
}

.table-body-tr:nth-child(even) {
    background-color: white;
}
.table-body-tr td {
    border: none;
}
</style>
